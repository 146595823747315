<template>
    <div class="shipments-list-item">
        <div class="shipments-list-item__col shipments-list-item__col_b-r shipments-list-item__col_data justify-between">
            <div
                class="relative flex flex__align-center flex-wrap mb-2 ml-3"
            >
                <slot></slot>
                <div
                    :class="['f-w-700 f-size-16 ml-5 text-ellipsis shipments-list-item__consignment-no', { 'ml-0': !showBookmark }]"
                    :title="value.consignment_no"
                >
                    {{ value.consignment_no || '–' }}
                </div>
                <div v-if="value.direction" class="absolute flex align-center w-full capitalize mt-2 mb-1" style="bottom: -28px">
                    <svg-importer
                        :key="`${value.consignment_no}_${value.direction}`"
                        :icon-name="`icons/${value.direction.toLowerCase()}`"
                        class="absolute"
                        style="left: -3px;"
                    />
                    <div class="ml-6" style="line-height: 24px;">
                        {{ value.direction }}
                    </div>
                </div>
            </div>

            <div class="flex">
                <template v-if="showBookmark" >
                    <icon
                        v-if="!getBookmarkType(value)"
                        :key="`bookmark-add`"
                        name="icons/bookmark"
                        class="hover:bg-white mr-6"
                        :disabled="value.loading"
                        hide-disabled
                        @click="addBookmark"
                    />
                    <icon
                        v-else-if="getBookmarkType(value) === bookmarkTypeDictionary.duo"
                        :key="`bookmark-duo`"
                        name="icons/bookmark-duo"
                        class="hover:bg-white mr-6 fill-rect-brand"
                        :disabled="value.loading"
                        hide-disabled
                        @click="removeBookmark"
                    />
                    <icon
                        v-else-if="getBookmarkType(value) === bookmarkTypeDictionary.manual"
                        :key="`bookmark-manual`"
                        name="icons/bookmark-red"
                        class="hover:bg-white mr-6"
                        :disabled="value.loading"
                        hide-disabled
                        @click="removeBookmark"
                    />
                    <icon
                        v-else-if="getBookmarkType(value) === bookmarkTypeDictionary.auto"
                        :key="`bookmark-auto`"
                        name="icons/bookmark-red"
                        class="hover:bg-white mr-6 stroke-brand fill-brand"
                        :disabled="value.loading"
                        hide-disabled
                        @click="removeBookmark"
                    />
                </template>

                <div
                    class="shipments-list-item__block shipments-list-item__block_mb_6 text-ellipsis"
                    :class="{ 'ml-3': !showBookmark }"
                >
                    <div class="f-s-10 color-grey">Sender's REF#:</div>
                    <div class="f-size-14 color-grey-65 text-ellipsis" :title="value.order_no">{{ value.order_no || '–' }}</div>
                </div>
            </div>
        </div>
        <div class="shipments-list-item__col shipments-list-item__col_info shipments-list-item__col_info--wide shipments-list-item__col_b-r">
            <div class="flex">
                <div class="shipments-list-item__block shipments-list-item__block_w_50">
                    <div class="color-grey">Recipient's REF#:</div>
                    <div class="text-subheader text-ellipsis" :title="value.consignee_reference">{{ value.consignee_reference || '-' }}</div>
                </div>

                <div class="shipments-list-item__block shipments-list-item__block_w_50">
                    <div class="color-grey">Weight / pcs:</div>
                    <div class="text-subheader text-ellipsis" :title="roundNumber(value.dimensions_weight)">{{ roundNumber(value.dimensions_weight) || '-' }} {{ value.dimensions_weight_unit }} / {{ value.noofpackages || '-' }} pcs</div>
                </div>
            </div>

            <div class="flex">
                <div class="shipments-list-item__block shipments-list-item__block_w_50 text-ellipsis">
                    <div class="color-grey">Goods type:</div>
                    <div class="text-subheader text-ellipsis" :title="value.goods_type_name">{{ value.goods_type_name || '–' }}</div>
                </div>


                <div class="shipments-list-item__block shipments-list-item__block_w_50">
                    <div class="color-grey">Price:</div>
                    <div class="text-subheader text-ellipsis" :title="value.price_data && value.price_data.amount ? roundNumber(value.price_data.amount) : '–'">
                        {{ value.price_data && value.price_data.amount ? roundNumber(value.price_data.amount) : '–' }} <span>{{ value.price_data.currency || '' }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="shipments-list-item__col shipments-list-item__col_info">
            <div class="shipments-list-item__block shipments-list-item__block_mb_6 text-ellipsis">
                <div class="color-grey">Carrier:</div>
                <div class="text-subheader text-ellipsis" :title="value.carrier_data.carrier_name">{{ value.carrier_data.carrier_name || '–' }}</div>
            </div>

            <div class="shipments-list-item__block">
                <div class="color-grey">Carrier service:</div>
                <div class="text-subheader text-ellipsis" :title="value.carrier_data.carrier_service_name">{{ value.carrier_data.carrier_service_name || '–' }}</div>
            </div>
        </div>
        <div class="shipments-list-item__col shipments-list-item__col_timeline">
            <div class="flex flex__justify-between flex__align-start4 shipments-list-item__timeline-info">
                <div class="shipments-list-item__timeline-block flex flex__column justify-start pr-1">
                    <div class="text-body text-ellipsis">
                        {{ value.pickup_name }}
                    </div>

                    <div class="text-body text-ellipsis">
                        {{ getFullAddressField({ row: value, key: 'pickup_city' }) }}, {{ getFullAddressField({ row: value, key: 'pickup_countrycode' }) }}
                    </div>

                    <div class="shipments-list-item__date-container text-body relative">
                        <span class="whitespace-no-wrap pr-1">
                            {{ startDate }}
                        </span>
                    </div>
                </div>

                <div class="shipments-list-item__timeline-block flex flex__column justify-start flex__align-center pr-1 text-center">
                    <div style="display: flex; gap: 4px; align-items: center">
                        <shipment-status :mode="value.transport_mode" :status="statusName" />
                        <svg-importer v-if="value.is_green" style="width: 22px" icon-name="icons/feather" />
                    </div>

                    <div v-if="value.exception_details" class="f-size-14 color-pink mt-a">
                        {{ prepareExceptionDetails }}
                    </div>

                    <div v-if="last_event" class="f-size-14 color-grey-65 mt-a">
                        {{ last_event }}
                    </div>
                </div>

                <div class="shipments-list-item__timeline-block flex flex__column flex__justify-end pr-1 text-right">
                    <div class="text-body text-ellipsis">
                        {{ value.delivery_name }}
                    </div>

                    <div class="text-body text-ellipsis">
                        {{ getFullAddressField({ row: value, key: 'delivery_city' }) }}, {{ getFullAddressField({ row: value, key: 'delivery_countrycode' }) }}
                    </div>

                    <div class="text-body text-ellipsis">
                        {{ endDate }}
                    </div>
                </div>
            </div>
            <time-line :value="value.status" :exception="prepareExceptionDetails" class="pl-1 pr-1" />
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import { requestErrors } from '~/utils/errors.js';

import TimeLine from './TimeLine.vue';
import ShipmentStatus from './Status.vue';
import { roundNumber } from '@/utils/roundNumber.js';
import ShipmentsAddressMixin from '@/pages/transport/shipments-address.mixin.js';

export default {
    props: {
        value: {
            type: Object,
            default: () => { return {} },
        },
        valueCheckbox: {
            default: '',
        },
        showBookmark: {
            default: true,
        }
    },

    mixins: [ShipmentsAddressMixin],

    data() {
        return {
            bookmarkTypeDictionary: {
                manual: 'manual',
                auto: 'auto',
                duo: 'duo',
            },
        }
    },

    computed: {
        startDate() {
            if (this.value.status === 'CREATED' || this.value.status === 'PRE_TRANSPORT') {
                return `Req. pickup: ${this.prepareDate(this.value.pick_up_starts_at)}`
            }

            if (this.value.status === 'IN_TRANSIT' || this.value.status === 'RECEPTION') {
                return `Actual pickup: ${this.prepareDate(this.value.actual_time_pick_up_at)}`
            }

            if (this.value.status === 'DELIVERED' || this.value.status === 'RETURNED') {
                return `Actual pickup: ${this.prepareDate(this.value.actual_time_pick_up_at)}`
            }

            return `Req. pickup: –`;
        },

        endDate() {
            if (this.value.actual_time_delivery_at) {
                return `Actual delivery: ${this.prepareDate(this.value.actual_time_delivery_at)}`;
            }

            return `Estimated Delivery Date:  ${this.prepareDate(this.value.estimated_delivery_ends_at || this.value.estimated_delivery_starts_at)}`;
        },

        user() {
            return this.$store.getters['auth/user']
        },

        adminLevel() {
            return this.user.level === 'super';
        },

        prepareExceptionDetails() {
            let value = this.value.exception_details;

            if (!value) {
                return '';
            }

            return value.split(',').map(val => val.trim()).filter(val => val).join(', ');
        },
        statusName() {
            const value = this.value.status;

            if (!value) {
                return '–';
            }

            if (value === 'CREATED') {
                return 'Created';
            }

            if (value === 'RECEPTION') {
                return 'Reception';
            }

            if (value === 'PRE_TRANSPORT') {
                return 'Pre transport';
            }

            if (value === 'CANCELLED') {
                return 'Cancelled';
            }

            if (value === 'IN_TRANSIT') {
                return 'In transit';
            }

            if (value === 'DELIVERED') {
                return 'Delivered';
            }

            if (value === 'RETURNED') {
                return 'Returned';
            }

            return value;
        },

        last_event() {
            return this.value?.last_event?.location;
        },

        expected_delay() {
            const expected_delay = this.value.expected_delay.toLowerCase();

            if (expected_delay === 'on time') {
                return { id: 1, value: expected_delay.toLowerCase()};
            }

            if (expected_delay.indexOf('late') != -1) {
                return { id: 2, value: expected_delay.toLowerCase() };
            }

            if (expected_delay.indexOf('early') != -1) {
                return { id: 3, value: expected_delay.toLowerCase() };
            }

            return { id: 0, value: 'Not available' };
        },
    },

    methods: {
        getBookmarkType(shipment) {
            if (!shipment.bookmarks?.length) {
                return '';
            }

            const bookmarksSet = new Set(shipment.bookmarks);
            const hasManual = bookmarksSet.has(2);
            const hasAuto = bookmarksSet.has(100);

            if (hasManual && hasAuto) {
                return this.bookmarkTypeDictionary.duo;
            }

            if (hasManual) {
                return this.bookmarkTypeDictionary.manual;
            }

            if (hasAuto) {
                return this.bookmarkTypeDictionary.auto;
            }

            return '';
        },
        roundNumber(value) {
            return roundNumber(value);
        },
        prepareDate(value) {
            if (!value) {
                return '–';
            }

            return moment(value).format('DD MMM YYYY');
        },
        async addBookmark() {
            this.value.loading = true;

            try {
                await axios.post(this.$apiUrl.consignments.bookmarks, {
                    ids: [this.value.id],
                });

                this.value.bookmarks.push(2);

                this.$snotify.success('Consignment bookmarked!');

            } catch (error) {
                this.$snotify.error(requestErrors(error));
            }

            this.value.loading = false;

            this.$forceUpdate();
        },

        async removeBookmark() {
            this.value.loading = true;

            try {
                this.value.bookmarks = [];

                await axios.delete(this.$apiUrl.consignments.bookmarks, {
                    data: {
                        ids: [this.value.id],
                    },
                });

                this.$snotify.success('Removed selected bookmark!');
            } catch (error) {
                this.$snotify.error(requestErrors(error));
            }

            this.value.loading = false;

            this.$forceUpdate();
        },
    },

    components: { TimeLine, ShipmentStatus },
}
</script>

<style lang="scss">
@import 'src/styles/pages/shipments-list-items.scss';
</style>
